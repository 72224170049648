import { Component } from '@angular/core';

@Component({
  selector: 'app-signup-done',
  standalone: true,
  imports: [],
  templateUrl: './signup-done.component.html',
  styleUrl: './signup-done.component.css'
})
export class SignupDoneComponent {

}
