import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatButton } from "@angular/material/button";

interface Status {
  loading: boolean;
}

@Component({
  selector: 'app-tenant-info',
  templateUrl: './tenant-info.component.html',
  styleUrls: ['./tenant-info.component.css']
})
export class TenantInfoComponent implements OnInit {
  @Input() startingForm!: FormGroup;
  @Input() finalForm: boolean = false;
  @Input() status: Status = { loading: false };

  @Output() subformInitialized: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() changeStep: EventEmitter<string> = new EventEmitter<string>();
  @Output() submitForm: EventEmitter<any> = new EventEmitter<MatButton>();

  public tenantInfoForm!: FormGroup;

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    if (this.startingForm) {
      this.tenantInfoForm = this._fb.group(this.startingForm);
    } else {
      this.tenantInfoForm = this._fb.group({
        companyName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
        tenantName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
        firstName: new FormControl('', [Validators.required, Validators.maxLength(20)]),
        lastName: new FormControl('', [Validators.required, Validators.maxLength(20)]),
        emailAddress: new FormControl('', [Validators.required, Validators.email]),
      })
    }
    this.subformInitialized.emit(this.tenantInfoForm);
  }

  errorMessage(item: AbstractControl | null): string {
    if (!item) {
      return '';
    }
    if (item.hasError('required')) {
      return 'This field is required';
    } else if (item.hasError('email')) {
      return 'Please enter a valid email address';
    } else if (item.hasError('minlength')) {
      return 'This field must be at least ' + item.errors?.['minlength'].requiredLength + ' characters';
    } else if (item.hasError('maxlength')) {
      return 'This field must be at most ' + item.errors?.['maxlength'].requiredLength + ' characters';
    }
    return '';
  };


  doChangeStep(direction: 'forward') {
    this.changeStep.emit(direction);
  }

  doSubmitForm() {
    this.submitForm.emit();
  }

  omit_special_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || (k >= 48 && k <= 57));
  }

}
