<div class="layout">
  <div class="header">
    <h3>Please fill in your provisioning details.</h3>
  </div>
  <form [formGroup]="solutionInfoForm">
    <mat-form-field class="field input">
      <mat-label>Region</mat-label>
      <mat-select id="region" formControlName="region" name="region" value="" ngDefaultControl required>
        @for (region of regions; track region) {
          <mat-option [value]="region.value">{{ region.viewValue }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="field input">
      <mat-label>Storage Class</mat-label>
      <mat-select id="storageClass" formControlName="storageClass" name="storageClass" value="" required>
        @for (storageClass of storageClasses; track storageClass) {
          <mat-option [value]="storageClass.value">{{ storageClass.viewValue }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <!--mat-form-field class="field input">
            <mat-label>Authcode</mat-label>
            <input matInput id="authcode" formControlName="authcode" name="authcode" required>
            @if (solutionInfoForm.get('authcode')?.invalid) {
            <mat-error style="margin-top:8px;">{{errorMessage(solutionInfoForm.get('authcode'))}}</mat-error>
            }
        </mat-form-field-->
    <mat-form-field class="field input">
      <mat-label>Commvault Software Version</mat-label>
      <mat-select id="softwareVersion" formControlName="softwareVersion" name="softwareVersion" value="" required>
        @for (softwareVersion of softwareVersions; track softwareVersion) {
          <mat-option [value]="softwareVersion.value">{{ softwareVersion.viewValue }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="field input">
      <mat-label>Capacity Requested (TB)</mat-label>
      <input
        matInput
        id="requestedCapacity"
        type="number"
        formControlName="requestedCapacity"
        name="requestedCapacity"
        required
      />
      @if (solutionInfoForm.get('requestedCapacity')?.invalid) {
        <mat-error style="margin-top: 8px">{{ errorMessage(solutionInfoForm.get('requestedCapacity')) }}</mat-error>
      }
    </mat-form-field>
    <div style="margin: 10px; align-self: flex-end">
      <button
        mat-button
        color="primary"
        style="
          border-radius: 25px;
          width: 120px;
          background-color: transparent;
          margin-right: 25px;
          margin-bottom: 20px;
        "
        (click)="doChangeStep('back')"
      >
        <span style="color: #53a7fa">Back</span>
      </button>
      <button
        mat-raised-button
        color="primary"
        style="border-radius: 25px; margin-bottom: 20px; width: 120px; background-color: #53a7fa"
        (click)="doSubmitForm()"
        [disabled]="solutionInfoForm.invalid || status.loading"
        type="submit"
      >
        @if (!status.loading) {
          <span>Submit</span>
        } @else {
          <mat-spinner [diameter]="25"></mat-spinner>
        }
      </button>
    </div>
  </form>
</div>
