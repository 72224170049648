import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegistrationService } from '../registration.service';
import { MatButton } from '@angular/material/button';

type Step = 'tenantInfo'

interface Status {
  loading: boolean
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})
export class RegistrationComponent {
  private currentStepBs: BehaviorSubject<Step> = new BehaviorSubject<Step>('tenantInfo');
  public currentStep$: Observable<Step> = this.currentStepBs.asObservable();
  public status: Status = { loading: false };

  public userForm!: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _snackbar: MatSnackBar,
    private _registrationService: RegistrationService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.userForm = this._fb.group({
      tenantInfo: null,
    });
  }

  subformInitialized(name: string, group: FormGroup) {
    this.userForm.setControl(name, group);
    this.cdr.detectChanges();
  }

  submitForm() {
    const formValues = this.userForm.getRawValue();
    const payload = this.buildPayload(formValues);
    console.log(JSON.stringify(payload));
    const snack = this._snackbar
    const router = this._router
    const status = this.status

    status.loading = true
    this._registrationService.register(payload).subscribe({
      next(response) {
        console.log(response);
        snack.open("Registration Successful", "OK")
        router.navigate(['/thanks']);
      },
      error(err) {
        console.log(err)
        snack.open("Registration Failed: " + err.error.reason, "FAIL")
        status.loading = false;
      },
    });
  }

  buildPayload(formValues: any) {
    return {
      tenantInfo: formValues.tenantInfo,
      registrationInfo: {
        provider: "datastorio_saas",
        provider_id: this.getProviderId()
      }
    }
  }

  getProviderId() {
    return "registration-provider-id"
  }
}
