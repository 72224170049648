<div class="layout">
  <div class="header">
    <h3>Please fill in your account details.</h3>
  </div>
  <form [formGroup]="tenantInfoForm">
    <mat-form-field class="field input">
      <mat-label>First Name</mat-label>
      <input matInput id="firstName" formControlName="firstName" name="firstName" required>
      @if (tenantInfoForm.get('firstName')?.invalid) {
      <mat-error style="margin-top:8px;">{{errorMessage(tenantInfoForm.get('firstName'))}}</mat-error>
      }
    </mat-form-field>
    <mat-form-field class="field input">
      <mat-label>Last Name</mat-label>
      <input matInput id="lastName" formControlName="lastName" name="lastName" required>
      @if (tenantInfoForm.get('lastName')?.invalid) {
      <mat-error style="margin-top:8px;">{{errorMessage(tenantInfoForm.get('lastName'))}}</mat-error>
      }
    </mat-form-field>
    <mat-form-field class="field input">
      <mat-label>Email Address</mat-label>
      <input matInput id="emailAddress" formControlName="emailAddress" name="emailAddress" email="true" required>
      @if (tenantInfoForm.get('emailAddress')?.invalid) {
      <mat-error style="margin-top:8px;">{{errorMessage(tenantInfoForm.get('emailAddress'))}}</mat-error>
      }
    </mat-form-field>
    <mat-form-field class="field input">
      <mat-label>Company Name</mat-label>
      <input matInput id="companyName" formControlName="companyName" name="companyName" required>
      @if (tenantInfoForm.get('companyName')?.invalid) {
      <mat-error style="margin-top:8px;">{{errorMessage(tenantInfoForm.get('companyName'))}}</mat-error>
      }
    </mat-form-field>
    <mat-form-field class="field input">
      <mat-label>Company Tenant Name</mat-label>
      <input matInput id="tenantName" formControlName="tenantName" name="tenantName"
        (keypress)="omit_special_char($event)" [maxLength]="10" minlength="3" required>
      @if (tenantInfoForm.get('tenantName')?.invalid) {
      <mat-error style="margin-top:8px;"> {{errorMessage(tenantInfoForm.get('tenantName'))}}</mat-error>
      }
    </mat-form-field>
    <div style="margin: 10px; align-self: flex-end;">
      <button mat-button color="primary"
        style="border-radius: 25px; width:120px;background-color:transparent; margin-right:25px;margin-bottom: 20px;">
        <span style="color: #53a7fa">Cancel</span>
      </button>
      @if (!finalForm) {
        <button mat-raised-button color="primary"
          style="border-radius: 25px; margin-bottom: 20px;width:120px;background-color:#53A7FA;"
          (click)="doChangeStep('forward')" [disabled]="tenantInfoForm.invalid" type="submit">Next</button>
      } @else {
        <button
          mat-raised-button
          color="primary"
          style="border-radius: 25px; margin-bottom: 20px; width: 120px; background-color: #53a7fa"
          (click)="doSubmitForm()"
          [disabled]="tenantInfoForm.invalid || status.loading"
          type="submit"
        >
        @if (!status.loading) {
          <span>Submit</span>
        } @else {
          <mat-spinner [diameter]="25"></mat-spinner>
        }
        </button>
      }
    </div>
  </form>
</div>
