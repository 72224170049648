import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http:HttpClient) {}

  register(payload:any, _snackbar:any) {
    console.log(environment)
    return this.http.post(`${environment.API_URL}/tenants`,payload).subscribe({
      next(response) {
        console.log(response);
       _snackbar.open("Registration Successful", "OK")
      },
      error(err) {
        console.log(err)
      },
    });
  }
}
