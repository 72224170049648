import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MkplOnboardingComponent } from './mkpl-onboarding/mkpl-onboarding.component';
import { SignupDoneComponent } from './signup-done/signup-done.component';

const routes: Routes = [
  {path: '', redirectTo: 'signup', pathMatch: 'full'},
  {path: 'signup', component: HomeComponent},
  {path: 'onboard-mkpl', component: MkplOnboardingComponent},
  {path: 'thanks', component: SignupDoneComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
