<div class="layout">
  <div class="header">
    <h3>Please fill in your account details.</h3>
  </div>
  <form class="form" #f="ngForm">
    <!-- <mat-form-field class="field input1">
      <mat-label>Tennant Identifier</mat-label>
      <input matInput (change)="updateField('tennantId', $event)">
    </mat-form-field> -->
    <mat-form-field class="field input">
      <mat-label>First Name</mat-label>
      <input matInput  id="firstName" [(ngModel)]="model.firstName" name="firstName" required>
    </mat-form-field>
    <mat-form-field class="field input">
      <mat-label>Last Name</mat-label>
      <input matInput id="lastName" [(ngModel)]="model.lastName" name="lastName" required>
    </mat-form-field>
    <mat-form-field class="field input">
      <mat-label>Email Address</mat-label>
      <input matInput id="emailAddress" [(ngModel)]="model.emailAddress" name="emailAddress" email="true" required>
    </mat-form-field>
    <mat-form-field class="field input">
      <mat-label>Company Name</mat-label>
      <input matInput id="name" [(ngModel)]="model.name" name="name" required>
    </mat-form-field>
    <mat-form-field class="field input">
      <mat-label>Company Abbreviated Name</mat-label>
      <input matInput id="abbrCompanyName" [(ngModel)]="model.abbrCompanyName" name="abbrCompanyName" (keypress)="omit_special_char($event)" [maxLength]="10" minlength="3" required>
    </mat-form-field>
    <div style="margin: 10px; align-self: flex-end;">
      <button mat-button color="primary"
        style="border-radius: 25px; width:120px;background-color:transparent; margin-right:25px;margin-bottom: 20px;">
        <span style="color: #53a7fa">Cancel</span>
      </button>
      <button mat-raised-button color="primary" [disabled]="!f.valid"
        style="border-radius: 25px; margin-bottom: 20px;width:120px;background-color:#53A7FA;" (click)="onSubmit()">Submit</button>
          
      </div>
  </form>
  </div>