export class Register {

  constructor(
    public firstName: string,
    public lastName: string,
    public emailAddress: string,
    public name: string,
    public abbrCompanyName: string
  ) {  }

}