<mat-drawer-container class="content-container">
  <mat-drawer class="sidepanel" mode="side" opened>
    <p class="logo">datastor.io</p>
  </mat-drawer>
  <mat-drawer-content>
    <div style="margin-left: 0px; display: flex; flex-direction: column;">
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <div style="display: flex; flex-direction: column; margin-bottom: 35px;">
          <h1 style="margin-left: 40px; margin-top: 41px;">Registration
          </h1>
          <!-- <p style="margin-left: 40px; margin-top: -15px;">dashboard/breadcrumb</p> -->
        </div>
      </div>

      <router-outlet></router-outlet>
    </div>
          </mat-drawer-content>
          </mat-drawer-container>