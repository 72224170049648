<ng-container [ngSwitch]="currentStep$ | async">
  <app-tenant-info
      *ngSwitchCase="'tenantInfo'"
      [startingForm]="userForm.get('tenantInfo')?.value"
      (subformInitialized)="subformInitialized('tenantInfo', $event)"
      (changeStep)="changeStep('tenantInfoStep', 'forward')"
  ></app-tenant-info>
  <app-solution-info
      *ngSwitchCase="'solutionInfo'"
      [startingForm]="userForm.get('solutionInfo')?.value"
      [status]="status"
      (subformInitialized)="subformInitialized('solutionInfo', $event)"
      (changeStep)="changeStep('solutionInfoStep', 'back')"
      (submitForm)="submitForm()"
    ></app-solution-info>
</ng-container>
