import { Component, EventEmitter, OnInit, Input, Output } from "@angular/core";
import {
  FormControl,
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators,
} from "@angular/forms";
import { MatButton } from "@angular/material/button";

interface Region {
  value: string;
  viewValue: string;
}

interface StorageClass {
  value: string;
  viewValue: string;
}

interface SoftwareVersion {
  value: string;
  viewValue: string;
}

interface Status {
  loading: boolean;
}
@Component({
  selector: "app-solution-info",
  templateUrl: "./solution-info.component.html",
  styleUrls: ["./solution-info.component.css"],
})
export class SolutionInfoComponent implements OnInit {
  @Input() startingForm!: FormGroup;
  @Input() status: Status = { loading: false };
  @Output() subformInitialized: EventEmitter<FormGroup> =
    new EventEmitter<FormGroup>();
  @Output() changeStep: EventEmitter<string> = new EventEmitter<string>();
  @Output() submitForm: EventEmitter<any> = new EventEmitter<MatButton>();
  public solutionInfoForm!: FormGroup;

  constructor(private _fb: FormBuilder) {}

  regions: Region[] = [
    { value: "us-east-1", viewValue: "US East (N. Virginia)" },
    { value: "us-east-2", viewValue: "US East (Ohio)" },
    //    { value: 'us-west-1', viewValue: 'US West (N. California)' },
    { value: "us-west-2", viewValue: "US West (Oregon)" },
  ];

  storageClasses: StorageClass[] = [
    { value: "s3ia", viewValue: "S3 Infrequent Access" },
    { value: "s3standard", viewValue: "S3 Standard" },
    { value: "gir", viewValue: "Glacier Infrequent Access" },
    //{ value: 'gda', viewValue: 'Glacier Deep Archive' },
  ];

  softwareVersions: SoftwareVersion[] = [
    { value: "11.32", viewValue: "11.32" },
    { value: "11.34", viewValue: "11.34" },
    { value: "11.36", viewValue: "11.36" },
  ];

  ngOnInit() {
    if (this.startingForm) {
      this.solutionInfoForm = this._fb.group(this.startingForm);
    } else {
      this.solutionInfoForm = this._fb.group({
        region: new FormControl("", [Validators.required]),
        storageClass: new FormControl("", [Validators.required]),
        //authcode: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]),
        softwareVersion: new FormControl("", [Validators.required]),
        requestedCapacity: new FormControl("", [Validators.required]),
      });
    }
    this.subformInitialized.emit(this.solutionInfoForm);
  }

  doChangeStep(direction: "back") {
    this.changeStep.emit(direction);
  }

  doSubmitForm() {
    this.submitForm.emit();
  }

  errorMessage(item: AbstractControl | null): string {
    if (!item) {
      return "";
    }
    if (item.hasError("required")) {
      return "This field is required";
    } else if (item.hasError("minlength")) {
      return (
        "This field must be at least " +
        item.errors?.["minlength"].requiredLength +
        " characters"
      );
    } else if (item.hasError("maxlength")) {
      return (
        "This field must be at most " +
        item.errors?.["maxlength"].requiredLength +
        " characters"
      );
    }
    return "";
  }
}
