<div class="header">
  <h2>Thank you for your interest in datastor.io.</h2>
  <!-- <h3>Please find us on the AWS Marketplace to sign up.</h3> -->
</div>
<ng-container [ngSwitch]="currentStep$ | async">
  <app-tenant-info
      *ngSwitchCase="'tenantInfo'"
      [startingForm]="userForm.get('tenantInfo')?.value"
      [finalForm]="true"
      (subformInitialized)="subformInitialized('tenantInfo', $event)"
      (submitForm)="submitForm()"
  ></app-tenant-info>
</ng-container>
