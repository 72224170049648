import { Component, Inject } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import {RegistrationService} from '../registration.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {Register} from './register';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
  MatSnackBarModule,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    MatSnackBarModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {

  constructor(private registrationService: RegistrationService, private _snackBar: MatSnackBar, private _router: Router) {
  }
  model = new Register('','','','','');  
  
  
  register() {
       //this.registrationService.register({"firstName":this.model.firstName, "lastName": this.model.lastName, 
        //"emailAddress": this.model.emailAddress,"companyName": this.model.name, "abbrCompanyName":this.model.abbrCompanyName}, this._snackBar, this._router)
  }
  onSubmit(
  ) {
    
    this.register()
    //this.model = new Register('','','','','');

  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  omit_special_char(event:any)
  {   
    var k;   
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || (k >= 48 && k <= 57)); 
  }

}
