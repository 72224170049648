import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegistrationService } from '../registration.service';

type Step = 'tenantInfo' | 'solutionInfo'

@Component({
  selector: 'app-mkpl-onboarding',
  templateUrl: './mkpl-onboarding.component.html',
  styleUrls: ['./mkpl-onboarding.component.css']
})
export class MkplOnboardingComponent implements OnInit {
  private currentStepBs: BehaviorSubject<Step> = new BehaviorSubject<Step>('tenantInfo');
  public currentStep$: Observable<Step> = this.currentStepBs.asObservable();
  private mkplId: string = '';

  public userForm!: FormGroup;

  constructor(private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router, 
    private _snackbar: MatSnackBar, 
    private _registrationService: RegistrationService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.userForm = this._fb.group({
      tenantInfo: null,
      solutionInfo: null
    });
    this.mkplId = this._route.snapshot.queryParams['x-amzn-marketplace-token'];
  }

  subformInitialized(name: string, group: FormGroup) {
    this.userForm.setControl(name, group);
    this.cdr.detectChanges();
  }

  changeStep(currentStep: string, direction: 'forward' | 'back') {
    switch (currentStep) {
      case 'tenantInfoStep':
        if (direction === 'forward') {
          this.currentStepBs.next('solutionInfo');
        };
        break;
      case 'solutionInfoStep':
        if (direction === 'back') {
          this.currentStepBs.next('tenantInfo');
        };
        break;
    }
  }

  submitForm() {
    const formValues = this.userForm.getRawValue();
    const payload = this.buildPayload(formValues, this.mkplId);
    console.log(JSON.stringify(payload));
    this._registrationService.register(payload, this._snackbar);
    this._router.navigate(['/thanks']);
    // TODO: call api to submit form.
  }

  buildPayload(formValues: any, mkplId: string) {
    return {
      tenantInfo: formValues.tenantInfo,
      solutionInfo: formValues.solutionInfo,
      mkplId: mkplId
    }
  }


}
